<template>
  <div>
    <modal-edit-space-utilization
      v-if="showModalEditSpaceUtilization"
      :showModal="showModalEditSpaceUtilization"
      :onClickCancel="closeEditModal"
    />

    <div>
      <label class="subtitle">
        <input
          type="checkbox"
          class="checkbox"
          @change="saveYieldSettings"
          v-model="yieldSettingsState.yieldSettings.ApplySpaceUtilization"
          :checked="yieldSettingsState.yieldSettings.ApplySpaceUtilization"
          :disabled="mYieldSettings.MeetingtypeId !== 1"
        />
        Use space utilization
      </label>
    </div>

    <article
      v-if="mYieldSettings.MeetingtypeId !== 1"
      class="message is-warning has-margin-top"
    >
      <div class="message-body">
        This option can only be activated on meeting rooms
      </div>
    </article>

    <article
      v-if="
        mYieldSettings.MeetingtypeId === 1 &&
          !mYieldSettings.ApplySpaceUtilization
      "
      class="message is-info has-margin-top"
    >
      <div class="message-body">
        Activate if you want to apply a discount or surcharge on utilization of
        the space.
      </div>
    </article>

    <div class="has-text-right">
      <a
        v-if="mYieldSettings.ApplySpaceUtilization"
        @click="setShowEditSpaceUtilization"
        class="is-size-7"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </span>
        <span>Edit</span>
      </a>
    </div>

    <table
      v-if="mYieldSettings.ApplySpaceUtilization"
      class="table is-fullwidth is-striped is-narrow"
    >
      <tbody>
        <tr>
          <td>Min. and max values</td>
          <td>
            <div>
              0% occupancy apply
              {{
                roundToZero(
                  yieldSettingsState.yieldSettings.SpaceUtilizationBottomValue *
                    100 -
                    100
                )
              }}% surcharge
            </div>
            <div>
              100% occupancy apply
              {{
                roundToZero(
                  yieldSettingsState.yieldSettings.SpaceUtilizationTopValue *
                    100 -
                    100
                )
              }}% discount
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

const ModalEditYieldSpaceUtilization = () =>
  import('@/components/Yield/ModalEditYieldSpaceUtilization')

export default {
  components: {
    'modal-edit-space-utilization': ModalEditYieldSpaceUtilization,
  },

  data() {
    return {
      mYieldSettings: null,
      showModalEditSpaceUtilization: false,
    }
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    saveYieldSettings() {
      let self = this

      //self.mYieldSettings.SpaceUtilizationTopValue = topValue
      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 204) {
            self.setYieldSettingsState(self.yieldSettingsState)
          }
        })
    },

    setShowEditSpaceUtilization() {
      this.showModalEditSpaceUtilization = true
    },

    closeEditModal() {
      this.showModalEditSpaceUtilization = false
    },
  },
}
</script>

<style></style>
